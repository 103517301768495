import {
  Box,
  BoxProps,
  Button,
  Container,
  Heading,
  HeadingProps,
} from '@chakra-ui/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import { WpLinkType } from '../types/WpLinkType';

type Props = {};

const NotFound = (props: Props) => {
  const data: {
    wp: {
      kudoSiteOptions: {
        notFound: {
          headline: string;
          text: string;
          button: WpLinkType;
        };
      };
    };
  } = useStaticQuery(graphql`
    query NotFoundQuery {
      wp {
        kudoSiteOptions {
          notFound {
            headline
            text
            button {
              title
              url
              target
            }
          }
        }
      }
    }
  `);
  const { headline, text, button } = data.wp.kudoSiteOptions.notFound;
  let isGatsbyLink = true;
  if (button.url.slice(0, 4) === 'http') isGatsbyLink = false;
  if (button.url.slice(0, 6) === 'mailto') isGatsbyLink = false;

  return (
    <Layout invertedFooter={true}>
      <Container marginY={{ base: '100px', md: '160px' }}>
        {headline.length > 0 && (
          <Heading {...headlineProps}>{headline}</Heading>
        )}
        {text.length > 0 && <Box {...textProps}>{text}</Box>}
        {button.url.length > 0 && (
          <>
            {isGatsbyLink ? (
              <Button
                as={Link}
                variant="blackToOrange"
                to={button.url}
                target={button.target}
              >
                {button.title}
              </Button>
            ) : (
              <Button
                as="a"
                variant="blackToOrange"
                href={button.url}
                target={button.target}
              >
                {button.title}
              </Button>
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};

const headlineProps: HeadingProps = {
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: 'shorter',
  marginY: { base: '24px', md: '40px' },
  maxWidth: '980px',
};

const textProps: BoxProps = {
  fontSize: { base: 'md', md: '2xl' },
  lineHeight: 'shorter',
  fontWeight: '900',
  marginY: { base: '24px', md: '40px' },
  maxWidth: '980px',
};

export default NotFound;
